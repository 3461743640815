.leaflet-pane.leaflet-tile-pane {
  @apply grayscale;
}

/*.leaflet-control a {*/
/*  @apply text-primary-text text-base font-normal hover:text-primary-text hover:no-underline;*/
/*}*/

.leaflet-control-attribution {
  @apply hidden;
}
