.splide__pagination {
  @apply mt-10;
}

.splide__pagination .splide__pagination__page {
  @apply w-5 h-5 rounded-full mx-2.5 bg-primary-disabled;
}

.slider-light-pagination .splide__pagination .splide__pagination__page {
  @apply bg-primary-disabled;
}

.splide__pagination .splide__pagination__page.is-active {
  @apply bg-primary;
}

.slider-light-pagination
  .splide__pagination
  .splide__pagination__page.is-active {
  @apply bg-primary-light;
}

@screen lg {
  .splide__pagination .splide__pagination__page {
    @apply w-6 h-6;
  }
}
